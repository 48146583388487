import * as R from 'ramda'
import axios from 'axios'
import {setDynatraceURL} from './envURL'
const envPrefix = 'REACT_APP_'
const propEqual = json => env => toBe => prop => {
  const fromJson = R.prop(prop, json)
  const value =
    fromJson === undefined ? R.prop(envPrefix + prop, env) : fromJson
  return value === toBe
}
const getProp = json => env => prop => {
  const fromJson = R.prop(prop, json)
  return fromJson === undefined ? R.prop(envPrefix + prop, env) : fromJson
}
export const prepareEnv = async () => {
  let json
  try {
    const config = await axios.get('/config.json');
    console.log('config data: ', config.data)
    json = typeof config.data === 'object' ? config.data : {}
    console.log('typeof config data: ', typeof config.data)
  } catch {
    json = {}
  }

  const check = propEqual(json)(process.env)
  const enabled = check('enabled')
  const variable = getProp(json)(process.env)

  baseURL = variable('BASE_URL')
  apiURL = variable('STATUS_API_URL')

  isDevelopment = baseURL === 'https://status.dev.plus1connect.danfoss.com'
  isStaging = baseURL === 'https://status.stg.plus1connect.danfoss.com'
  isSandbox = baseURL === 'https://status.sbx.plus1connect.danfoss.com'
  isProduction = baseURL === 'https://status.plus1connect.danfoss.com'

  if (isDevelopment) {
    console.log('DEV')
    console.log('baseURL:', baseURL)
    console.log('apiURL:', apiURL)
  } else if (isStaging) {
    console.log('STG')
    console.log('baseURL:', baseURL)
    console.log('apiURL:', apiURL)
  } else if (isSandbox) {
    console.log('SBX')
    console.log('baseURL:', baseURL)
    console.log('apiURL:', apiURL)
  } else if (isProduction) {
    console.log('PRD')
    console.log('baseURL:', baseURL)
    console.log('apiURL:', apiURL)
  } else {
    console.log('Localhost')
    console.log('baseURL:', baseURL)
    console.log('apiURL:', apiURL)
  }
  await setDynatraceURL(apiURL)
  isGARouteTrackEnabled = enabled('ENABLE_ROUTE_GA')
}

export let baseURL
export let apiURL
export let isDevelopment
export let isStaging
export let isSandbox
export let isProduction
export let isGARouteTrackEnabled
